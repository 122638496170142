import React, { useEffect } from "react";
import "./App.css";
import { Link } from "react-scroll";
import { Waypoint } from "react-waypoint";
import "animate.css/animate.min.css";
import { ReactComponent as AppleStoreSVG } from "./assets/AppleStoreSVG.svg";
import { ReactComponent as GooglePlaySVG } from "./assets/GooglePlaySVG.svg";
import { ReactComponent as TwitterIcon } from "./assets/twitter-icon.svg";
import { ReactComponent as LinkedInIcon } from "./assets/linkedin-icon.svg";
import { ReactComponent as InstagramIcon } from "./assets/instagram-icon.svg";

import translationScreen from "./assets/translation-screen.png";
import connectImage from "./assets/connect.png";
import worldImage from "./assets/world-image.png";
// import airobotImage from "./assets/airobot.png";
import logoCC from "./assets/logo_cc.png";
import Helmet from "react-helmet"; //Newly added
import Scrollspy from "react-scrollspy";

// import { Parallax } from "react-parallax";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const appStoreUrl = "https://itunes.apple.com/us/app/6446968637";
const playStoreUrl =
  "https://play.google.com/store/apps/details?id=com.oneyerge.reactnativefirebase";

function App() {
  useEffect(() => {
    if (typeof window !== "undefined") {
      initAnimations();
    }
  }, []);

  const initAnimations = () => {
    const fadeIn = {
      opacity: 0,
      duration: 1.5,
    };

    const slideInRight = {
      ...fadeIn,
      x: 50,
      scrub: true,
    };

    const slideInLeft = {
      ...fadeIn,
      x: -50,
    };

    const basicTrigger = {
      start: "top center",
      toggleActions: "play none none none",
    };

    gsap.from(".responsive-image-connectImage", {
      scrollTrigger: {
        ...basicTrigger,
        trigger: ".responsive-image-connectImage",
      },
      ...slideInRight,
    });

    gsap.from(".responsive-image-worldImage", {
      scrollTrigger: {
        ...basicTrigger,
        trigger: ".responsive-image-worldImage",
      },
      ...slideInLeft,
    });

    // Add more animations here
  };

  return (
    <div className="App">
      <Helmet>
        <meta charSet="utf-8" />
        <title>ChatCulture: Innovative Translation and Communication</title>
        <meta
          name="description"
          content="Revolutionize your global communication with ChatCulture, featuring Chat Translation, Camera Translation, AR Translation, Document Translation, Object Identification, and Voice-to-Text. Experience the future of language translation today."
        />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="ChatCulture" />
        <meta
          property="og:title"
          content="ChatCulture: Innovative Translation and Communication"
        />
        <meta
          property="og:description"
          content="Revolutionize your global communication with ChatCulture, featuring Chat Translation,  Camera Translation, AR Translation, Document Translation, Object Identification, and Voice-to-Text. Experience the future of language translation today."
        />
        <meta property="og:image" content={logoCC} />
        <meta property="og:url" content={window.location.href} />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <header className="App-header header-shape">
        <div className="top-banner">
          <div className="nav-content">
            <Link to="/" className="logo-main">
              {/* Add your placeholder logo SVG here */}
              <img src={logoCC} alt="LogoCC" className="logo-img" />
              ChatCulture
            </Link>
            <nav className="nav-links">
              <Scrollspy
                // items={["connect", "method", "features", "download"]}
                items={["intro", "connect", "features", "download"]}
                currentClassName="active"
                offset={-300}
              >
                <Link
                  to="intro"
                  smooth={true}
                  duration={1000}
                  activeClass="active"
                >
                  Introduction
                </Link>
                <Link
                  to="connect"
                  smooth={true}
                  duration={1000}
                  activeClass="active"
                >
                  Connect
                </Link>
                {/* <Link
                  to="method"
                  smooth={true}
                  duration={1000}
                  activeClass="active"
                >
                  Method
                </Link> */}
                <Link
                  to="features"
                  smooth={true}
                  duration={1000}
                  activeClass="active"
                >
                  Features
                </Link>
                <Link
                  to="download"
                  smooth={true}
                  duration={1000}
                  activeClass="active"
                >
                  Download
                </Link>
              </Scrollspy>
            </nav>
          </div>
        </div>
        <div className="header-section-content">
          <div className="left-column left-column-content">
            <h1>Break Language Barriers Instantly</h1>

            <p>
              ChatCulture bridges cultures and connects people globally, with
              features like Chat, Voice, AR, and Camera Translation.
            </p>
            <Link
              to="download"
              smooth={true}
              duration={1000}
              className="App-download"
            >
              <button className="cta-button">Get Started</button>
            </Link>
          </div>
          <div className="right-column">
            <img
              src={translationScreen}
              alt="Translation Screen"
              className="responsive-image-translationScreen"
            />
          </div>
        </div>
        <div className="header-shape"></div>
      </header>

      <main>
        <section className="youtube-section" id="intro">
          <div className="iframe-wrapper">
            <iframe
              src="https://www.youtube.com/embed/wycsif04u6I?si=VGqX0hW1x8_j-qTP"
              title="ChatCulture Introduction Video"
              // frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
        </section>

        <section className="App-connect full-page" id="connect">
          <Waypoint
            onEnter={() => {
              document
                .getElementById("connect")
                .classList.add("animate__fadeIn");
            }}
          >
            <div className="section-content">
              <div className="text-container">
                <h1>
                  Connecting People, Bridging Cultures: Discover the Power of
                  Language
                </h1>
                <ul className="custom-bullet">
                  <li>
                    <strong>Expand Your Horizons:</strong> Effortlessly connect
                    with new people across cultures and countries, bridging
                    language barriers and building meaningful relationships.
                  </li>
                  <li>
                    <strong>Language Learning AI Pal:</strong> Enhance your
                    language skills with our new AI Pal. Engage in realistic
                    chats with an AI in your target language, with translations
                    at your fingertips. Learning a language has never been more
                    interactive and practical.
                  </li>
                  <li>
                    <strong>Stay Close to Loved Ones:</strong> Chat with family
                    and friends from various locations in your native language,
                    ensuring clear and effortless communication.
                  </li>
                  <li>
                    <strong>User-Friendly Interface:</strong> Navigate with ease
                    through ChatCulture's intuitive and simple design, perfect
                    for users of all ages and experience levels.
                  </li>
                  <li>
                    <strong>Constantly Evolving:</strong> Stay ahead of the
                    curve with our frequent updates and new features, ensuring
                    you get the best experience as part of the ChatCulture
                    community.
                  </li>
                </ul>
              </div>

              <div className="image-container">
                <img
                  src={connectImage}
                  alt="Connect"
                  className="responsive-image-connectImage"
                />
              </div>
            </div>
          </Waypoint>
        </section>
        {/* <section className="App-method full-page" id="method">
          <Waypoint
            onEnter={() => {
              document
                .getElementById("method")
                .classList.add("animate__fadeIn");
            }}
          >
            <div className="section-content">
              <div className="image-container">
                <img
                  src={airobotImage}
                  alt="Airobot"
                  className="responsive-image-airobotImage"
                />
              </div>
              <div className="text-container">
                <h1>
                  Bridging Language Barriers Through Cutting-Edge Technology
                </h1>
                <ul className="custom-bullet">
                  <li>
                    <strong>Real-Time Language Mastery:</strong> Instantly
                    detect and translate languages with advanced LLMs.
                    Experience seamless communication in your native language!
                  </li>
                  <li>
                    <strong>Enhanced Accuracy:</strong> Our proprietary prompt
                    engineering and LLM modification ensure unparalleled
                    translation precision. (Patent Pending).
                  </li>
                  <li>
                    <strong>All-Platform Support:</strong> Effortlessly connect
                    across iOS, Android, and Web platforms.
                  </li>
                  <li>
                    <strong>Multilingual AI Pal:</strong> Experience fluid
                    conversations with swift real-time translations and
                    intelligent predictions, now with the added benefit of our
                    AI Pal for real-world language practice.
                  </li>
                  <li>
                    <strong>Adaptive Learning & Optimization:</strong> Our
                    patent pending Translation Matrix and advanced machine
                    learning guarantee continuous improvement and optimized
                    results.
                  </li>
                </ul>
              </div>
            </div>
          </Waypoint>
        </section> */}

        <section className="App-features full-page" id="features">
          <Waypoint
            onEnter={() => {
              document
                .getElementById("features")
                .classList.add("animate__fadeIn");
            }}
          >
            <div className="section-content">
              <div className="image-container">
                <img
                  src={worldImage}
                  alt="World"
                  className="responsive-image-worldImage"
                />
              </div>
              <div className="text-container">
                <h1>
                  Explore Our Top Features: A New Dimension of Communication
                </h1>
                <ul className="custom-bullet">
                  <li>
                    <strong>Advanced Translation Capabilities:</strong> Our
                    versatile translation tools, including Camera, AR, and
                    Document Translation, streamline your communication across
                    languages.
                  </li>
                  <li>
                    <strong>
                      Global Connectivity and Auto-Language Detection:
                    </strong>{" "}
                    Effortlessly connect worldwide with our auto-detecting and
                    translating platform, overcoming any language barrier.
                  </li>
                  <li>
                    <strong>Original Message Access with a Single Tap:</strong>{" "}
                    Simply tap to reveal original messages, ensuring clear and
                    transparent communication.
                  </li>
                  <li>
                    <strong>Interactive AI Pal Chat:</strong> Enhance your
                    language skills by practicing with our AI Pal, featuring
                    different personas for a diverse and realistic learning
                    experience.
                  </li>
                  <li>
                    <strong>Extensive Language Support:</strong> Our app
                    supports over 77 languages, catering to a diverse global
                    user base in the digital age.
                  </li>
                </ul>
              </div>
            </div>
          </Waypoint>
        </section>
        <section className="download-section full-page" id="download">
          <h2 className="download-heading">Download Now</h2>
          <div className="App-download">
            <div className="download-icons">
              <a href={appStoreUrl} target="_blank" rel="noopener noreferrer">
                <AppleStoreSVG className="store-icon" />
              </a>
              <a href={playStoreUrl} target="_blank" rel="noopener noreferrer">
                <GooglePlaySVG className="store-icon" />
              </a>
            </div>
          </div>
        </section>
      </main>
      <footer>
        <div className="footer-columns">
          <div className="footer-column">
            <h4>About ChatCulture</h4>
            <p>
              ChatCulture is a cutting-edge language translation app that
              enables seamless communication across 77+ languages. Our mission
              is to break down language barriers and bring people together from
              all around the world.
            </p>
          </div>
          <div className="footer-column">
            <h4>Quick Links</h4>
            <ul>
              <li>
                <a href="terms">Terms and Conditions</a>
              </li>
              <li>
                <a href="privacy">Privacy Policy</a>
              </li>
              <li>
                <a
                  href="mailto:chatcultureapp@gmail.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Contact Us
                </a>
              </li>
            </ul>
          </div>
          <div className="footer-column">
            <h4>Follow Us</h4>
            <div className="social-icons">
              <a
                href="https://twitter.com/chatcultureapp"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Twitter"
              >
                <TwitterIcon alt="Twitter" />
              </a>
              <a
                href="https://www.linkedin.com/company/chatcultureapp"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="LinkedIn"
              >
                <LinkedInIcon alt="LinkedIn" />
              </a>
              <a
                href="https://www.instagram.com/chatcultureapp/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="LinkedIn"
              >
                <InstagramIcon alt="Instagram" />
              </a>
              {/* Add more social media icons here */}
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <span>
            © {new Date().getFullYear()} ChatCulture. All rights reserved.
          </span>
        </div>
      </footer>
    </div>
  );
}

export default App;
