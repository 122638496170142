import React from 'react';
import './App.css';
import './Terms.css';
import { Link } from 'react-router-dom';
import logoCC from './assets/logo_cc.png';

function Privacy() {
  return (
    <div className="Terms">
      <header className="Terms-header">
        <Link to="/" className="logo">
          {/* Add your placeholder logo SVG here */}
             <img src={logoCC} alt="LogoCC" className="logo-img" />
          ChatCulture
        </Link>
      </header>

      <main className="Terms-content">
        <h1>Privacy Policy</h1>
        <p>
          <strong>Introduction:</strong><br/>
          This privacy policy explains how we collect, use, and protect your personal data when you use our mobile application. We take your privacy seriously and strive to ensure that your personal data is secure and protected at all times. By using our mobile application, you agree to the terms of this privacy policy.

          <br/><strong>Data We Collect:</strong><br/>
          We may collect personal data from you when you use our mobile application. The data we collect may include, but is not limited to:
          • Your name and email address
          • Your location and language preferences
          • Your feedback and ratings for the translation of the original texts
          • The original and translated texts
          • Detected languages and any data associated with language detection
          • Information regarding your device, such as the operating system and device type

          <br/><strong>How We Use Your Data:</strong><br/>
          We use your personal data to improve our language translation and language detection capabilities, as well as to improve the AI assistant. We may collect this data through various means, including but not limited to, surveys, feedback forms, and chat logs. Specifically, we may use your data for the following purposes:
          • Feedback and Ratings: We collect feedback and ratings from you for the translation of the original texts. By pressing on any modified message, you can see the original text and provide a rating based on the translation. This feedback is used to improve our language translation and language detection capabilities.
          • Localization of Errors: We may collect your data to analyze and localize any gaps in the translation that require further attention. This can be done through a sentiment or a simple keyword search through the historical data. These errors are localized and extracted, and a human expert can do the more accurate translation. These data are used to further train and modify the LLMs to make them more accurate.
          • Text Analysis: We may collect your data to modify the existing LLMs by understanding the areas of interest through a text analysis. If you are interested in a recent event but cannot get an answer from the AI assistant, then those areas are marked to further train the LLMs in the future.
          • Geographical Information: The detected languages of the users are stored if possible with the geographic information so that what languages per the location can be extracted for further use (please see potential application section).

          <br/><strong>Modification of Existing LLMs:</strong><br/>
          We may use the data we collect from you to improve our language translation and language detection capabilities, as well as to improve the AI assistant. The data collected include gaps in language translation and language detection, as well as the areas of interest that you suggested with your feedback and with the inability to reach the desired outcome. We may further train our LLMs with this data to improve the accuracy and effectiveness of the language translation and detection capabilities, as well as to improve the AI assistant.

          <br/><strong>Protection of Your Data:</strong><br/>
          We take the security and protection of your personal data seriously. We implement various measures to protect your personal data from unauthorized access, modification, or destruction. Your personal data is stored in secure servers, and we restrict access to your personal data to only those who need to access it.
                <br/><strong>Retention of Your Data:</strong><br/>
      We will retain your personal data for as long as necessary to fulfill the purposes for which it was collected. When we no longer need your personal data, we will securely delete or destroy it.

      <br/><strong>Cookies and Tracking Technologies:</strong><br/>
      We may use cookies and similar tracking technologies, such as pixels and web beacons, to collect information about your use of our mobile application. Cookies are small files that are stored on your device when you use our mobile application. We may use both session cookies, which expire when you close your browser, and persistent cookies, which stay on your device until they expire or you delete them. We use cookies and tracking technologies to analyze user behavior and improve our mobile application. We may use cookies to personalize your experience, such as remembering your language preferences or login information, and to show you relevant ads. We may also use cookies and tracking technologies to gather information about your device, such as your IP address, device type, and operating system. You can choose to disable cookies or set your device to alert you when cookies are being sent. However, please note that some features of our mobile application may not function properly if cookies are disabled. By using our mobile application, you consent to our use of cookies and tracking technologies as described in this privacy policy.

      <br/><strong>Limitation of Liability:</strong><br/>
      To the extent permitted by law, we shall not be liable for any direct, indirect, incidental, special, consequential, or punitive damages, including but not limited to, damages for loss of profits, goodwill, use, data, or other intangible losses, resulting from your use of our mobile application or your reliance on any information provided through our mobile application. We make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability with respect to the mobile application or the information, products, services, or related graphics contained in the mobile application for any purpose. Any reliance you place on such information is therefore strictly at your own risk. We shall not be responsible or liable for any loss or damage of any kind whatsoever arising as a result of your use of any third-party mobile applications or services that you access through our mobile application. Furthermore, we shall not be responsible for any unauthorized access to your personal data or for any loss or damage caused to your personal data as a result of your failure to follow our security recommendations or your sharing of login credentials with third parties.

      <br/><strong>Contact Information:</strong><br/>
      If you have any questions or concerns about this privacy policy, please contact us at chatcultureapp@gmail.com.
    </p>
  </main>

  <footer className="Terms-footer">
    <div className="footer-columns">
      <div className="footer-column">
         <Link to="/" className="back-to-home">Back to Home</Link>
      </div>
    </div>
    <div className="footer-bottom">
      <span>© {new Date().getFullYear()} ChatCulture. All rights reserved.</span>
    </div>
  </footer>
</div>
);
}

export default Privacy;

