import React from "react";
import "./App.css";
import "./Terms.css";
import { Link } from "react-router-dom";
import logoCC from "./assets/logo_cc.png";

function Terms() {
  return (
    <div className="Terms">
      <header className="Terms-header">
        <Link to="/" className="logo">
          {/* Add your placeholder logo SVG here */}
          <img src={logoCC} alt="LogoCC" className="logo-img" />
          ChatCulture
        </Link>
      </header>

      <main className="Terms-content">
        <h1>Terms and Conditions</h1>
        <ul>
          <strong>1. Introduction</strong>
          <br />
          These Terms and Conditions ("Terms") govern your use of the mobile
          application ("Application") developed by OmniMinds LLC ("Developer").
          By using the Application, you agree to be bound by these Terms, which
          constitute a legally binding agreement between you and Developer. If
          you do not agree to these Terms, do not use the Application.
          <br />
          <strong>2. Use of Application</strong>
          <br />
          The Application uses APIs including an OpenAI API in combination with
          propriety methods to provide real-time translation of messages in a
          chat application. The accuracy of the translation is not guaranteed
          and Developer is not responsible for any errors or inaccuracies in the
          translation. The Application is provided "as is" without any warranty,
          express or implied.
          <br />
          <strong>3. Limitation of Liability</strong>
          <br />
          To the fullest extent permitted by law, Developer shall not be liable
          for any direct, indirect, incidental, special, consequential or
          exemplary damages, including but not limited to, damages for loss of
          profits, goodwill, use, data or other intangible losses, business
          interruption losses, arising out of or in connection with your use of
          the Application or any API that the Application is using, whether
          based on contract, tort, negligence, strict liability or otherwise,
          even if Developer has been advised of the possibility of such damages.
          <br />
          <strong>4. Indemnification</strong>
          <br />
          You agree to indemnify, defend and hold harmless Developer and its
          officers, directors, employees, agents, affiliates, successors and
          assigns from and against any and all claims, damages, liabilities,
          costs and expenses, including reasonable attorneys' fees, arising out
          of or in connection with your use of the Application or any API that
          the Application is using, your breach of these Terms, or your
          violation of any law or the rights of any third party.
          <br />
          <strong>5. User Content</strong>
          <br />
          As a user of the Application, you acknowledge that you are solely
          responsible for the content of the messages you send and receive
          through the Application. The Developer assumes no responsibility for
          any content that is transmitted through the Application. By using the
          Application, you agree not to use it to send or receive any messages
          that are illegal, defamatory, obscene, threatening, harassing, or
          otherwise objectionable. Any violation of these terms and conditions
          may result in the removal or deletion of your user content without
          prior notice or liability to the Developer.
          <br />
          <strong>5.1 Moderation and Prevention of Abusive Behavior</strong>
          <br />
          To prevent abusive behavior and objectionable content, the Application
          will implement the following measures: - Require users to agree to
          these Terms and Conditions, which specifically prohibit the use of the
          Application for transmitting objectionable content or engaging in
          abusive behavior - Implement a user reporting system that allows users
          to flag objectionable content or abusive behavior for review by the
          Developer - Regularly monitor and review user-generated content to
          identify and remove objectionable content and abusive users -
          Implement a warning or penalty system for users who violate the Terms,
          which may include temporary or permanent bans from the Application -
          Utilize automated content filtering systems to help detect and remove
          objectionable content before it is displayed within the Application
          The Developer reserves the right to monitor user content and remove or
          delete any content that violates these terms and conditions or is
          otherwise deemed inappropriate. Such actions may be taken without
          prior notice or liability to the Developer. By using the Application,
          you agree to comply with all applicable laws and regulations regarding
          the transmission of data and information.
          <br />
          <strong>6. Intellectual Property</strong>
          <br />
          The Application, including all content, text, graphics, user
          interfaces, visual interfaces, photographs, sounds, artwork, computer
          code (including HTML, CSS, JavaScript, and other code), programs,
          software, products, information, and documentation, as well as the
          design, structure, selection, coordination, expression, "look and
          feel," and arrangement of the Application, are protected by copyright,
          trademark, trade dress, patent, and other intellectual property laws,
          and are the sole and exclusive property of OmniMinds LLC or its
          licensors. You are strictly prohibited from modifying, copying,
          reproducing, reverse engineering, distributing, transmitting,
          displaying, performing, publishing, licensing, creating derivative
          works from, transferring, or selling any of the foregoing, without the
          prior written consent of OmniMinds LLC or its licensors. You
          acknowledge and agree that any unauthorized use or copying of the
          Application or any part thereof, may cause irreparable harm and damage
          to OmniMinds LLC or its licensors, and that in such event, OmniMinds
          LLC or its licensors shall be entitled to obtain injunctive relief in
          addition to any other legal remedies available to it. You agree that
          you will not use the Application for any unauthorized purposes,
          including but not limited to copying, modifying, or reverse
          engineering the Application, or any part thereof. You acknowledge and
          agree that any such unauthorized use constitutes a breach of these
          terms and conditions and may subject you to civil and criminal
          liability. You further acknowledge and agree that any ideas,
          suggestions, feedback, or other information provided by you to
          OmniMinds LLC regarding the Application or any aspect thereof,
          including any new features or functionality, shall be owned by
          OmniMinds LLC and may be used by OmniMinds LLC in any manner it deems
          appropriate, without any obligation to compensate you or provide
          attribution.
          <br />
          <strong>7. Termination</strong>
          <br />
          Developer reserves the right to terminate these Terms and your access
          to the Application at any time, with or without cause, and without
          prior notice. Upon termination, you must immediately cease all use of
          the Application. In addition to the above, upon termination, Developer
          reserves the right to delete or remove any of your user data or
          content that may have been stored or accessed through the Application.
          Developer shall have no liability to you or any third party for any
          damages, losses, or expenses incurred as a result of any such
          termination or removal of user data or content. You acknowledge and
          agree that Developer shall not be required to provide you with any
          compensation, refund, or other payment in connection with any such
          termination.
          <br />
          <strong>8. Governing Law and Jurisdiction</strong>
          <br />
          These Terms shall be governed by and construed in accordance with the
          laws of USA, without giving effect to any principles of conflicts of
          law. Any dispute arising out of or in connection with these Terms
          shall be subject to the exclusive jurisdiction of the courts of USA.
          Furthermore, these Terms shall apply worldwide, and any disputes
          arising out of or in connection with these Terms shall be subject to
          the exclusive jurisdiction of the courts in the country where the
          Developer is headquartered. The parties agree to waive any objection
          to venue and personal jurisdiction in such courts and agree to submit
          to the jurisdiction of such courts. Developer makes no representation
          that the Application is appropriate or available for use in locations
          outside of USA, and accessing it from territories where the content is
          illegal is prohibited. Those who choose to access the Application from
          other locations do so at their own initiative and are responsible for
          compliance with local laws.
          <br />
          <strong>9. Modification of Terms</strong>
          <br />
          Developer reserves the right to modify these Terms at any time,
          without prior notice. Your continued use of the Application after any
          such modification shall constitute your acceptance of the modified
          Terms. It is your responsibility to review these Terms regularly to
          ensure that you are aware of any changes.
          <br />
          <strong>10. No Waiver</strong>
          <br />
          The failure of Developer to enforce any right or provision of these
          Terms shall not be deemed a waiver of such right or provision. If any
          provision of these Terms is held to be invalid or unenforceable, the
          remaining provisions shall remain in full force and effect.
          <br />
          <strong>11. Entire Agreement</strong>
          <br />
          These Terms constitute the entire agreement between you and Developer
          regarding the use of the Application, and supersede all prior or
          contemporaneous communications and proposals, whether oral or written,
          between you and Developer.
          <br />
          <strong>12. Assignment</strong>
          <br />
          Developer may assign these Terms, in whole or in part, at any time
          without notice to you. You may not assign these Terms or transfer any
          rights to use the Application without the prior written consent of
          Developer.
          <br />
          <strong>13. No Agency</strong>
          <br />
          These Terms do not create any agency, partnership, joint venture, or
          employment relationship, and you may not represent yourself as an
          agent, employee or partner of Developer.
          <br />
          <strong>14. Third-Party Links</strong>
          <br />
          The Application may contain links to third-party websites or services
          that are not owned or controlled by Developer. Developer has no
          control over, and assumes no responsibility for, the content, privacy
          policies, or practices of any third-party websites or services. You
          acknowledge and agree that Developer shall not be responsible or
          liable, directly or indirectly, for any damage or loss caused or
          alleged to be caused by or in connection with the use of or reliance
          on any such content, goods or services available on or through any
          such websites or services.
          <br />
          <strong>15. Data Loss and Data Breach</strong>
          <br />
          While Developer takes some measures to protect user data and prevent
          data loss or data breach, Developer cannot guarantee the security of
          user data. In the event of a data loss or data breach, Developer can
          take reasonable steps to notify affected users and authorities, but
          Developer is not responsible for any damages or losses that may result
          from such an incident.
          <br />
          <strong>16. Hacking and Unauthorized Access</strong>
          <br />
          Developer takes measures to prevent unauthorized access to the
          Application and user data, but Developer cannot guarantee that the
          Application will be completely free from hacking or other unauthorized
          access. In the event of unauthorized access to the Application or user
          data, Developer can take reasonable steps to mitigate the impact of
          such an incident, but Developer is not responsible for any damages or
          losses that may result from such an incident.
          <br />
          <strong>17. API Availability and Performance</strong>
          <br />
          The Application relies on a third party APIs such as OpenAI, Firebase,
          and Developer cannot guarantee the availability or performance of
          these APIs. Developer is not responsible for any damages or losses
          that may result from the unavailability or poor performance of the
          API.
          <br />
          <strong>18. Compliance with Laws</strong>
          <br />
          You are responsible for complying with all applicable laws, rules, and
          regulations in connection with your use of the Application, including
          but not limited to laws related to data privacy, intellectual
          property, and export controls.
          <br />
          <strong>19. Disclaimer of Endorsement</strong>
          <br />
          The inclusion of any third-party link in the Application does not
          imply endorsement by Developer of the linked website or service.
          Developer is not responsible for any content, products, or services
          available on or through any linked website or service.
          <br />
          <strong>20. Subscription Terms of Service</strong>
          <br />
          If the Application offers any subscription-based services, such
          services will be governed by the Subscription Terms of Service
          ("Subscription Terms"). By subscribing to any such services, you agree
          to be bound by the Subscription Terms, which constitute a legally
          binding agreement between you and Developer. If you do not agree to
          the Subscription Terms, do not subscribe to any such services.
          <br />
          <strong>21. Modification of Subscription Terms</strong>
          <br />
          OmniMinds LLC reserves the right to modify these Subscription Terms at
          any time, without prior notice. Your continued use of the Service
          after any such modification shall constitute your acceptance of the
          modified Subscription Terms. It is your responsibility to review these
          Subscription Terms regularly to ensure that you are aware of any
          changes.
          <br />
          <strong>22. Third-Party Payment Processors</strong>
          <br />
          OmniMinds LLC may use third-party payment processors to facilitate the
          payment of subscription fees. You agree that OmniMinds LLC is not
          responsible for any errors or omissions that may occur during the
          payment process, including any errors or omissions caused by the
          third-party payment processor.
          <br />
          <strong>23. No Refunds</strong>
          <br />
          By subscribing to the Service, you acknowledge and agree that the
          subscription fees are non-refundable. If you cancel your subscription
          before the month or year is over, you will not receive any refunds.
          <br />
          <strong>24. Privacy Policy</strong>
          <br />
          By subscribing to the Service, you also agree to OmniMinds LLC's
          Privacy Policy, which can be found on the OmniMinds LLC website. The
          Privacy Policy describes how OmniMinds LLC collects, uses, and shares
          your personal information. It is your responsibility to review the
          Privacy Policy and understand how your personal information is being
          used.
          <br />
          <strong>25. Disclaimer of Liability for Third-Party Services</strong>
          <br />
          The Application may allow you to access third-party services, such as
          social media platforms, through links or integrations. Developer is
          not responsible for the content, accuracy, or availability of these
          third-party services, and you access them at your own risk. Developer
          disclaims all liability for any damages or losses that may result from
          your use of third-party services.
          <br />
          <strong>26. Auto-Renewing Subscriptions</strong>
          <br />
          The Application offers two auto-renewable subscription types for
          ChatCulture Plus: "Monthly Subscription" and "Yearly Subscription."
          The Monthly Subscription is priced at $9.99 per month, and the Yearly
          Subscription is priced at $99.99 per year. Your subscription will be
          charged to your iTunes account upon confirmation of purchase and will
          automatically renew for the same duration (monthly or yearly) unless
          auto-renew is turned off at least 24 hours before the end of the
          current subscription period. Current subscriptions cannot be cancelled
          during the active subscription period. However, you can manage your
          subscription and/or turn off auto-renewal by visiting your iTunes
          Account Settings after purchase. By subscribing to ChatCulture Plus,
          you agree to the following disclosure requirements for auto-renewing
          subscriptions: • Title of auto-renewing subscription: ChatCulture Plus
          Monthly Subscription or ChatCulture Plus Yearly Subscription, as
          applicable • Length of subscription: Monthly for the Monthly
          Subscription and yearly for the Yearly Subscription • Price of
          subscription: $9.99 per month for the Monthly Subscription and $99.99
          per year for the Yearly Subscription, and any applicable taxes • Links
          to our Privacy Policy and Terms of Use are accessible within the
          Application.
          <br />
          <strong>27. Changes to Subscription Pricing and Features</strong>
          <br />
          OmniMinds LLC reserves the right to change the pricing and features of
          its subscriptions at any time. If the pricing or features of your
          subscription change, you will be notified in advance, and the new
          pricing and features will take effect at the start of your next
          subscription period. If you do not agree with the changes, you may
          cancel your subscription before the start of the next subscription
          period. If you continue to use the Application after the start of the
          next subscription period, you are deemed to have accepted the changes.
          <br />
          <strong>28. No Liability for Force Majeure Events</strong>
          <br />
          Developer shall not be liable for any delay, failure, or interruption
          of the Application or any API that the Application is using resulting
          from causes beyond its reasonable control, including but not limited
          to acts of God, acts of civil or military authority, strikes,
          lockouts, fires, floods, epidemics, or other natural disasters.
          <br />
          <strong>29. No Responsibility for User Conduct</strong>
          <br />
          Developer is not responsible for monitoring the conduct of users of
          the Application, and shall have no liability for any user conduct that
          violates these Terms or any applicable law or regulation.
          <br />
          <strong>30. Severability</strong>
          <br />
          If any provision of these Terms is held to be invalid, illegal, or
          unenforceable, the validity, legality, and enforceability of the
          remaining provisions shall not in any way be affected or impaired.
          <br />
          <strong>31. Headings</strong>
          <br />
          The headings in these Terms are for convenience only and shall not
          affect the interpretation of these Terms.
          <br />
          <strong>32. No Waiver of Rights</strong>
          <br />
          The failure of Developer to exercise or enforce any right or provision
          of these Terms shall not constitute a waiver of such right or
          provision.
          <br />
          <strong>33. Assignment</strong>
          <br />
          Developer may assign these Terms to any third party without your
          consent. You may not assign or transfer your rights or obligations
          under these Terms without the prior written consent of Developer.
          <br />
          <strong>34. Language</strong>
          <br />
          These Terms may be presented in multiple languages. In the event of
          any conflict between the English version and any other language
          version, the English version shall prevail.
          <br />
          a. These Terms are presented and made available exclusively in
          English.
          <br />
          b. You acknowledge and accept that it is your responsibility to
          understand and comprehend the content of these Terms. If you are
          uncertain about the content, meaning, or implications of these Terms,
          you are advised to seek an appropriate translation or legal
          clarification in your native language.
          <br />
          c. The Developer is under no obligation to provide these Terms in any
          other language. In the event of any discrepancies, misinterpretations,
          or misunderstandings arising from translations undertaken by any party
          other than the Developer, the English version of these Terms shall
          prevail.
          <br />
          d. The Developer will not be held responsible for any errors,
          misunderstandings, or legal implications arising from translations of
          these Terms into any other language.
          <br />
          <strong>35. Entire Agreement</strong>
          <br />
          These Terms, together with any applicable Subscription Terms, Privacy
          Policy, and any other agreements or policies referenced herein,
          constitute the entire agreement between you and Developer regarding
          the use of the Application, and supersede all prior or contemporaneous
          communications and proposals, whether oral or written, between you and
          Developer.
          <br />
          <strong>36. Additional Comment</strong>
          <br />
          These Terms are intended to be comprehensive, but they may not cover
          every possible scenario that could arise in connection with your use
          of the Application. If you have any questions or concerns about these
          Terms, please contact Developer at the contact information provided in
          Section 15.
          <br />
          <strong>37. Use of AI Pal Feature/strong</strong>
          <strong>37.1. Nature of AI Pal Conversations/strong</strong>
          <br />
          AI Pal is a simulated AI feature that allows users to engage in
          conversation with AI entities representing various backgrounds. All
          interactions with AI Pal are generated through artificial intelligence
          and should not be perceived as interactions with real humans. <br />
          <strong>37.2. Purpose of AI Pal</strong>
          <br />
          The AI Pal feature is primarily designed for language practice and
          cultural experience simulation. It is not intended for seeking
          professional advice, making decisions, or engaging in sensitive or
          private conversations. <br />
          <strong>37.3. Misinterpretations and Misunderstandings</strong>
          <br />
          Developer is not responsible for any misinterpretations,
          misunderstandings, or actions taken based on interactions with AI Pal.
          Users should exercise their own discretion and judgment when
          interpreting AI Pal responses. <br />
          <strong>37.4. Data Storage</strong>
          <br />
          Interactions with AI Pal may be stored to improve the quality of the
          service. Users should not share personal, sensitive, or confidential
          information with AI Pal. <br />
          <strong>37.5. Cultural and Linguistic Sensitivities</strong>
          <br />
          Developer strives to ensure that AI Pal is culturally and
          linguistically sensitive. However, it may not be perfect in
          representing every nuance of every culture or language. Users should
          approach AI Pal with an open mind and avoid drawing generalized
          conclusions based on their interactions. <br />
          <strong>37.6. No Real Human Interaction</strong>
          <br />
          The AI Pal feature does not offer real human interaction, and users
          should be cautious of developing emotional dependencies on or
          expecting genuine emotional support from the AI entities. <br />
          <strong>37.7. No Illegal or Inappropriate Usage</strong>
          <br />
          Users should not use the AI Pal feature for illegal or inappropriate
          purposes, including but not limited to: promoting hate speech,
          engaging in illegal activities, or sharing inappropriate content.{" "}
          <br />
          <strong>37.8. Limitation of Liability</strong>
          <br />
          Developer is not responsible for any damages, losses, or consequences
          arising out of or related to interactions with AI Pal, including but
          not limited to reliance on information provided by AI Pal, emotional
          distress, or any decision made based on AI Pal interactions.
          <br />
          <strong>37.9. Termination of AI Pal Service</strong>
          <br />
          Developer reserves the right to modify, suspend, or terminate the AI
          Pal feature at any time without prior notice. <br />
          <strong>38. User Feedback on AI Pal</strong>
          <br />
          Users are encouraged to provide feedback on the AI Pal feature. Such
          feedback will be used to improve the feature's accuracy and cultural
          sensitivity. However, Developer is under no obligation to act on every
          piece of feedback received. <br />
          <strong>39. Use of Personalized AI Backgrounds</strong>
          <br />
          The backgrounds like "French baker", "Spanish dancer", and others are
          fictional constructs intended for immersive language and cultural
          practice. They are not based on real individuals, and any resemblance
          to actual persons, living or dead, or to actual events or locales is
          entirely coincidental. <br />
          <strong>40. Camera Translation and Augmented Reality Features</strong>
          <br />
          The Application offers camera translation and augmented reality (AR)
          translation features. You acknowledge that these features are provided
          for convenience and may not be error-free. The Developer is not
          responsible for any inaccuracies in translations provided through
          these features. You agree not to use these features for any critical
          or sensitive translations where inaccuracies could lead to significant
          consequences.
          <strong>41. Document Translation and Upload</strong>
          <br />
          The Application allows for document text translation and document
          upload for translation purposes. You are solely responsible for any
          content you upload for translation. The Developer is not liable for
          any loss, alteration, or misuse of the content during or after the
          translation process.
          <strong>42. Profile Photo and Object Detection</strong>
          <br />
          The Application permits users to upload a profile photo and uses
          object detection for translation services. You agree to only upload
          images you have the legal right to use and acknowledge that the
          Developer is not responsible for any misuse of images. Object
          detection translations are provided as-is, and the Developer is not
          liable for inaccuracies or misidentifications.
          <strong>43. Voice Enabled Features and Real-Time Translation</strong>
          <br />
          The Application provides voice-enabled features and real-time
          translation capabilities. You acknowledge that these services are
          dependent on various factors, including but not limited to, speech
          clarity, accent, and background noise, and may not always provide
          accurate translations. The Developer is not liable for any
          misinterpretation or reliance on these translations.
          <strong>44. Text-to-Speech Functionality</strong>
          <br />
          The Application offers text-to-speech functionality, including
          gender-based voice options. You acknowledge that the quality and
          accuracy of text-to-speech translations can vary and agree that the
          Developer is not responsible for any miscommunication or
          misunderstanding arising from their use.
          <strong>45. Data Usage and Privacy Concerns</strong>
          <br />
          The use of camera translation, document upload, voice-enabled
          features, and real-time translation may involve the processing and
          storage of personal data. You agree to comply with all applicable data
          protection laws and regulations in relation to any personal data you
          provide or generate through the use of the Application. The Developer
          commits to protecting user privacy and data in accordance with its
          Privacy Policy.
          <strong>46. Acceptable Use of AI and Translation Features</strong>
          <br />
          You agree not to misuse the AI and translation features provided by
          the Application, including but not limited to using them for illegal
          purposes, infringing on intellectual property rights, or generating
          harmful or offensive translations. The Developer reserves the right to
          suspend or terminate your access to these features for any misuse.
          <strong>Survival</strong>
          <br />
          Sections 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
          20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36,
          37, 38, 39, 40, 41, 42, 43, 44, 45 and 46 of these Terms and the
          Subscription Terms shall survive any termination or expiration of
          these Terms or the Subscription Terms.
          <br />
          <strong>Contact Information</strong>
          <br />
          If you have any questions or concerns about these Terms, please
          contact us at chatcultureapp@gmail.com.
        </ul>
      </main>

      <footer className="Terms-footer">
        <div className="footer-columns">
          <div className="footer-column ">
            <Link to="/" className="back-to-home">
              Back to Home
            </Link>
          </div>
        </div>
        <div className="footer-bottom">
          <span>
            © {new Date().getFullYear()} ChatCulture. All rights reserved.
          </span>
        </div>
      </footer>
    </div>
  );
}

export default Terms;
